import React from 'react';
import GalleryBackground from './galleryBackground';
import poemRo from '../images/rollovers/poem-ro.png'

export default function SinglePoem({ children, title }) {
  return (
    <GalleryBackground title="Poems" imgSrc={poemRo}>
    <div className="single-poem-container">
        <h1 className="single-poem-header">{ title }</h1>
            {children}
        </div>
    </GalleryBackground>
    );
}
