import React from 'react';
import SinglePoem from '../../../components/singlePoem';
import Paper from '@mui/material/Paper'

export default function Poem() {
  return (
    <SinglePoem title="On Seeing Mr Penniman" >
        <p>
            I. <br></br><br></br>
            Little Richard is in a wheelchair! <br></br>
            We were sitting in the airport, <br></br>
            The airport in Paris, de Gaulle, <br></br>
            and we were waiting, waiting, <br></br>
            for our plane, having a <br></br>
            meal as we waited, <br></br>
            when Little Richard, in his <br></br>
            finest hair and velvet collar <br></br>
            and shiny rings, was pushed <br></br>
            in on a chair.  I never <br></br>
            knew he was even sick or <br></br>
            that his legs were so tired <br></br>
            from all that hooting and singing <br></br>
            and standing on pianos. <br></br>
            I never knew he was now so <br></br>
            weary and still. <br></br>
            Oh Little Richard we love you get up <br></br>
            <br></br><br></br>
        II. <br></br><br></br>
            The Originator spoke sweetly—<br></br>
            “English tea,” he asked<br></br>
            the sour French waitress,<br></br>
            conveying then with<br></br>
            the greatest difficulty<br></br>
            his desire<br></br>
            to ensure she get her tip.<br></br>
            She hemmed and frowned and blew<br></br>
            in her confusion.<br></br>
            “Honey,” he finally breathed,<br></br>
            in exasperated southern lilt,<br></br>
            “all I want to do<br></br>
            is make sure get your share.”<br></br>
            <br></br><br></br>
        III. <br></br><br></br>
            As we left, I stood<br></br>
            at my table and gathered<br></br>
            my things.  He never<br></br>
            pretended that I had<br></br>
            not been staring.<br></br>
            He grinned and waved,<br></br>
            his fingers playing<br></br>
            in the air<br></br>
            a gentle scale of<br></br>
            both hello, and of bon voyage.<br></br>
        </p>
    </SinglePoem>
      );
}
